import {Services} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import paymentApi from '../../api/payments';

export interface ServicesState {
  value?: Services[];
  isLoading: boolean;
  isError: boolean;
}

const initialState: ServicesState = {
  value: [],
  isLoading: false,
  isError: false,
};

export const getServicesAsync = createAsyncThunk('services/fetch', async () => {
  const services = paymentApi.getServices();

  return services;
});

export const loadServices = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServicesAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServicesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        const services = action.payload;

        const planNames = Object.keys(services);
        const plans = planNames.map((planName) => {
          return {
            plan: planName,
            services: services[planName],
          };
        }) as Services[];
        state.value = plans;
      })
      .addCase(getServicesAsync.rejected, (state) => {
        state.isError = true;
      });
  },
});

export const selectServices = (state: RootState) => state.services.value;
export const selectServicesFetchLoading = (state: RootState) =>
  state.services.isLoading;
export const selectServicesFetchError = (state: RootState) =>
  state.services.isError;

export default loadServices.reducer;
