import React from 'react';

import './StepItem.scss';
import {Badge} from 'react-bootstrap';
import classNames from 'classnames';
import Icon from '../../../components/Icons/Icon';
import Loading from '../../../components/Loading';

interface RequiredProps {
  title: string;
  status: 'completed' | 'not-completed' | 'no-status';
  description: string | React.ReactNode;
  icon: 'addTemplate' | 'publishDesign' | 'fulfillment';
  loading: boolean;
  onClick: () => void;
  disabled: boolean;
}

const StepItem: React.FC<RequiredProps> = ({
  title,
  status,
  icon,
  description,
  loading = true,
  onClick,
  disabled,
}) => {
  const isCompleted = status === 'completed';
  const isNoStatus = status === 'no-status';

  const handleOnClick = () => {
    if (disabled) return;
    onClick();
  };

  return (
    <div className="home-step-item">
      <div className={`step-box`} onClick={handleOnClick}>
        <div>
          {!loading && !isNoStatus && (
            <>
              <Badge
                className={classNames('box-badge-status', {
                  'box-badge-status--completed': isCompleted,
                })}
              >
                {isCompleted ? 'Done' : 'To do'}
              </Badge>
              <Icon name={icon} />
            </>
          )}
          {loading && <Loading spinnerOnly />}
        </div>
        <div className="box-header">
          <span className="box-header-title">{title}</span>
          <span className="box-header-description">{description}</span>
        </div>
        <Icon name="rightIcon" />
      </div>
    </div>
  );
};

export default StepItem;
