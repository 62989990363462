import React from 'react';
import DropdownMenu from '../DropdownMenu';
// Components
import Icon from '../Icons/Icon';

// Contracts
import {GetShopsResponse} from '@gfxco/contracts';

import './GFXStoreItemComponent.scss';

const GFXStoreItemComponent: React.FC<{
  shop: GetShopsResponse;
  isSelectedStore?: boolean;
  onClick?: () => void;
  icon?: 'addImagePlus' | 'defaultStoreImage';
  isDeleteAvailable?: boolean;
  onDeleteStore?: (shopId: number) => void;
}> = ({
  shop,
  isSelectedStore = false,
  onClick,
  icon = 'addImagePlus',
  isDeleteAvailable = false,
  onDeleteStore,
}) => {
  const dropdownMenuRef = React.useRef<HTMLDivElement>(null);

  return (
    <div
      id="GFXStoreItemComponent"
      onClick={(event) => {
        if (dropdownMenuRef.current?.contains(event.target as Node)) {
          return;
        }
        onClick && onClick();
      }}
    >
      {shop.shop_image_url ? (
        <img
          className="StoreImage"
          src={`${shop.shop_image_url}?v=${Date.now()}`}
          loading="lazy"
          alt="Store Image"
        />
      ) : (
        <div className="StoreImage__blank">
          <Icon
            name={icon}
            classes="StoreImage__Blank_icon"
            width={27}
            height={27}
          />
        </div>
      )}

      <div className="StoreInfo">
        {isSelectedStore && (
          <div className="StoreInfo__selectedStore">
            <Icon
              name="checkIconBlue"
              classes="StoreInfo__selectedStore__icon"
            />
            <span className="StoreInfo__selectedStore__span">
              Selected store
            </span>{' '}
          </div>
        )}
        <p
          className={`gfx-ellipsis StoreInfo__shopName ${
            !isSelectedStore && 'no-selected'
          }`}
        >
          {shop.name}
        </p>
        <p
          className={`gfx-ellipsis StoreInfo__shopDescription ${
            !isSelectedStore && 'no-selected'
          }`}
        >
          {shop.description}
        </p>
      </div>
      {onDeleteStore && (
        <div className="store-menu-container" ref={dropdownMenuRef}>
          <DropdownMenu
            className="store-options-menu"
            itemOptions={[
              {
                id: 'deleteStore',
                label: 'Delete store',
                icon: 'delete',
                disabled: !shop.is_owner || !isDeleteAvailable,
                tooltip:
                  !shop.is_owner || !isDeleteAvailable
                    ? 'You can only delete stores if you are the owner of two or more.'
                    : undefined,
              },
            ]}
            onItemClick={(id) => {
              if (onDeleteStore) {
                onDeleteStore(shop.id);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GFXStoreItemComponent;
