import React from 'react';
import {useParams} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import CloseConfirmationModal from '../../../components/CloseConfirmationModal';
import {selectExceptions} from '../../../features/exceptions/loadExceptions';
import {useAppSelector} from '../../../app/hooks';

import ExceptionsApi from '../../../api/exceptions';

import {IColor, IExceptionResult} from '@gfxco/contracts';

import './ExceptionList.scss';

import ExceptionItem from './ExceptionItem';

export interface RequiredProps {
  colors: IColor[];
  sortBy: string;
  searchBy: string;
  onEdit: (exception: IExceptionResult) => void;
}
export interface OptionalProps {
  children?: React.ReactNode;
  onReload?: () => Promise<void>;
}

type ExceptionListProps = RequiredProps & OptionalProps;

export const ExceptionList: React.FC<ExceptionListProps> = ({
  colors,
  onEdit,
  onReload,
}) => {
  const params = useParams();
  const {id} = params;

  const [errorMessage, setErrorMessage] = React.useState('');
  const [showDeleteWarning, setShowDeleteWarning] = React.useState(false);
  const exceptions = useAppSelector(selectExceptions);

  const [exceptionToDelete, setExceptionToDelete] = React.useState<
    number | number[]
  >();

  const handleOnDelete = async (exceptionId: number) => {
    try {
      if (!id) return;
      const params = {templateId: +id, exceptionId};
      await ExceptionsApi.deleteException(params);
    } catch (error) {
      console.log(error);
      setErrorMessage('Error deleting a exception');
    }
  };

  const handleOnDeleteAll = async (exceptionIds: number[]) => {
    try {
      if (!id) return;
      const params = {templateId: +id, exceptionIds};
      await ExceptionsApi.deleteBulkExceptions(params);
    } catch (error) {
      setErrorMessage('Error deleting all exception');
    }
  };

  const onDelete = (exception: {id?: number[] | number}) => {
    setShowDeleteWarning(true);
    if (exception.id) setExceptionToDelete(exception.id);
  };

  const confirmDelete = () => {
    if (exceptionToDelete) {
      if (Array.isArray(exceptionToDelete)) {
        handleOnDeleteAll(exceptionToDelete);
      } else {
        handleOnDelete(exceptionToDelete);
      }
    }
    if (onReload) onReload();
  };

  return (
    <div id="ExceptionList">
      <Alert variant="danger" show={errorMessage !== ''}>
        {errorMessage}
      </Alert>

      {exceptions?.map((exception, index) => (
        <ExceptionItem
          key={`exception-${index}`}
          tag={exception.tag}
          collectionId={exception.collectionId}
          colorName={exception.color}
          image={exception.image}
          exceptions={exception.exceptions!}
          colors={colors}
          type={exception.type!}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ))}

      <CloseConfirmationModal
        show={showDeleteWarning}
        onContinue={() => {
          confirmDelete();
          setShowDeleteWarning(false);
        }}
        onClose={() => setShowDeleteWarning(false)}
        title="Are you sure you want to delete the exception?"
        text=" If you delete the exception, you will lose some behaviours in your template. Are you sure you want to delete it?"
        continueText="Yes, delete exception"
        closeText="No, and close window"
      />
    </div>
  );
};

export default ExceptionList;
