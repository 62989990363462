import React, {useState} from 'react';
import {ReactComponent as CollectionIcon} from '../../../../assets/icons/collection-icon.svg';
import {ReactComponent as ImageIcon} from '../../../../assets/icons/image-icon.svg';
import {ReactComponent as TextColor} from '../../../../assets/icons/text-icon.svg';
import GarmentColor from '../../../../components/ShareActions/GarmentColor';
import ImageAction from '../../../../components/ShareActions/ImageAction';
import Tags from '../../../../components/ShareActions/Tags';
import DropdownButton from '../DropdownButton';
import ExceptionsBox from './ExceptionsBox';
import {IColor, IImage, IExceptionResult, IException} from '@gfxco/contracts';
import {colors as defaultTextColors} from '../../../../libs/getDefaultTextColors';
import {ITagList, IExceptionNextStep} from '../types';
import './HideStatement.scss';

export interface RequiredProps {
  colors: IColor[];
  tags: ITagList[];
  images: IImage[];
  exception: IExceptionResult;
  onNextStep: (params: IExceptionNextStep) => void;
}
export interface OptionalProps {}

type HideStatementProps = RequiredProps & OptionalProps;

const OPTIONS_DISABLED = {
  image: ['fillColor'],
  tag: ['image', 'fillColor'],
};

export const HideStatement: React.FC<HideStatementProps> = (props) => {
  const exceptions = props.exception.exceptions || [];

  const [exceptionToList, setExceptionsToList] =
    useState<IException[]>(exceptions);
  const [typeSelected, setTypeSelected] = useState<string>('');

  const handleSelect = (
    items: {image?: IImage; fillColor?: string; tag?: ITagList}[],
  ) => {
    const current = exceptions.filter(({type}) => type !== typeSelected);

    const newExceptions = items.map(({tag, image, fillColor}) => {
      if (typeSelected === 'tag' && tag)
        return {
          type: typeSelected,
          tag: tag.tag,
          collectionId: tag.id,
          id: exceptions.find((e) => e.collectionId === tag.id)?.id,
        };

      if (image && typeSelected === 'image')
        return {
          type: typeSelected,
          imageId: image.id,
          image,
          id: exceptions.find((e) => e.imageId === image.id)?.id,
        };

      return {
        type: typeSelected,
        fillColor,
        id: exceptions.find((e) => e.fillColor === fillColor)?.id,
      };
    });

    const nextStep = {
      data: {
        exception: {
          ...props.exception,
          exceptions: [...current, ...newExceptions],
        },
      },
    };

    setExceptionsToList([...current, ...newExceptions]);
    props.onNextStep(nextStep);
  };

  const handleOnClick = (type: 'fillColor' | 'tag' | 'image' | 'color') => {
    if (props.exception.type) if (!isDisabled(type)) setTypeSelected(type);
  };

  const getSelectedList = (type: string) => {
    if (typeSelected === '') return;
    return exceptions
      .filter(({type}) => type === typeSelected)
      .map((exception) => {
        if (type === 'fillColor') return {fillColor: exception.fillColor};
        if (type === 'image') return {image: exception.image};
        return {tag: {tag: exception.tag!, id: exception.collectionId!}};
      });
  };

  const handleOnDelete = (item: {
    tag?: string;
    color?: IColor;
    image?: IImage;
  }) => {
    const {color, tag, image} = item;
    let index = 0;
    let newExceptions = exceptions;

    if (color)
      index = exceptions.findIndex(({fillColor}) => fillColor === color?.hex);

    if (image) index = exceptions.findIndex((e) => e.image?.id === image?.id);

    if (tag) index = exceptions.findIndex((e) => e.tag === tag);

    if (index > -1) {
      newExceptions = [
        ...newExceptions.slice(0, index),
        ...newExceptions.slice(index + 1),
      ];
    }

    const nextStep = {
      data: {
        exception: {
          ...props.exception,
          exceptions: newExceptions,
        },
      },
    };
    setExceptionsToList(newExceptions);
    props.onNextStep(nextStep);
  };

  const isDisabled = (type: string) => {
    if (props.exception.type === 'image' || props.exception.type === 'tag')
      return (
        props.exception.type &&
        OPTIONS_DISABLED[props.exception.type].includes(type)
      );
  };

  const handleListSearch = (value: string) => {
    const newList = exceptions.filter(
      (exception) =>
        exception.color?.toLowerCase()?.includes(value) ||
        exception.tag?.toLowerCase().includes(value) ||
        exception.image?.name.toLowerCase().includes(value) ||
        exception.fillColor?.toLowerCase().includes(value),
    );

    setExceptionsToList(newList);
  };

  const handleOnCloseDropdown = () => {
    setTypeSelected('');
  };

  return (
    <div id="HideStatement">
      <div className="header">
        <span className="title">Add an Exception</span>
        <div className="divider"></div>
      </div>
      <div className="content-step">
        <div className="content-description">
          <span className="content-title">Select your Hide Statement</span>
        </div>
        <div className="body">
          <div>
            <div className="item-container preview-container">
              <div
                className={`option ${
                  props.exception.type === 'tag' ? 'tag-preview' : ''
                }`}
              >
                <span>
                  You selected the{' '}
                  {`${
                    props.exception.type === 'tag'
                      ? 'Collection'
                      : props.exception.type
                  }`}
                </span>
                {props.exception.type === 'color' && (
                  <GarmentColor
                    showTitle={false}
                    color={props.colors.find(
                      (color) => color.name === props.exception.color,
                    )}
                  />
                )}
                {props.exception.type === 'tag' &&
                  props.exception.tag &&
                  props.exception.collectionId && (
                    <Tags
                      tagItem={{
                        tag: props.exception.tag,
                        id: props.exception.collectionId,
                      }}
                    />
                  )}
                {props.exception.type === 'image' && (
                  <ImageAction
                    name={props.exception.image?.name}
                    url={props.exception.image?.imageUrl}
                  />
                )}
              </div>
            </div>
            <DropdownButton
              type="tag"
              items={props.tags}
              icon={<CollectionIcon width="55" height="55" />}
              text="Select your exception collection"
              onSelectMulti={handleSelect}
              onClose={handleOnCloseDropdown}
              onClick={() => handleOnClick('tag')}
              selected={typeSelected === 'tag'}
              selectedItems={getSelectedList(typeSelected)}
              isMulti
            />

            <DropdownButton
              type="image"
              items={props.images}
              icon={<ImageIcon width="55" height="55" />}
              text="Select your exception image"
              onSelectMulti={handleSelect}
              onClose={handleOnCloseDropdown}
              onClick={() => handleOnClick('image')}
              selected={typeSelected === 'image'}
              selectedItems={getSelectedList(typeSelected)}
              isMulti
              disable={isDisabled('image')}
            />

            <DropdownButton
              type="fillColor"
              items={defaultTextColors}
              icon={<TextColor />}
              text="Select your text exception color"
              onSelectMulti={handleSelect}
              onClose={handleOnCloseDropdown}
              onClick={() => handleOnClick('fillColor')}
              selected={typeSelected === 'fillColor'}
              selectedItems={getSelectedList(typeSelected)}
              isMulti
              disable={isDisabled('fillColor')}
              showHex={true}
            />
          </div>
          <ExceptionsBox
            exceptions={exceptionToList}
            onDelete={handleOnDelete}
            onSearch={handleListSearch}
            isEmpty={!!exceptions.length}
          />
        </div>
      </div>
      <div className="footer">
        <div className="divider"></div>
      </div>
    </div>
  );
};

export default HideStatement;
