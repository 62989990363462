import React from 'react';
import useQueryParams from '../../hooks/useQueryParams';
import {
  GetTemplateByIdResponse,
  IBuilderSlots,
  TemplateCanvaObj,
  IColor,
  WalkthroughStep,
} from '@gfxco/contracts';

import {Col, Row} from 'react-bootstrap';
import SlotConfiguration from './SlotConfigurationList';
import LinkedSlots from '../../components/LinkedSlot';
import WalkthroughTapSection from '../../components/WalkthroughTapSection';
import ExceptionsPage from '../ExceptionsPage';
import StickersPage from '../StickersPage';

import './SlotControlsTabs.scss';
import GFXTabs from '../../components/Tabs/Tabs';

type TabsElementsMap = {
  [key: string]: React.ReactNode;
};

type SlotControlsTabsOptionalProps = {};

type SlotControlTabsRequiredProps = {
  canvaTemplate: GetTemplateByIdResponse | undefined;
  templateId: string | number;
  updateSlotValues: (
    slotId: number | string,
    data: IBuilderSlots | Partial<IBuilderSlots>,
  ) => void;
  slotsOrder: number[];
  garmentColors: IColor[];
  onUpdateTemplate: (canvaTemplate: Partial<GetTemplateByIdResponse>) => void;
  onUpdateWalkthrough: (walkthroughActions: WalkthroughStep[]) => void;
  slots: IBuilderSlots[];
  isSaving: boolean;
};

type SlotControlsTabsProps = Partial<SlotControlsTabsOptionalProps> &
  SlotControlTabsRequiredProps;

const SlotControlsTabs: React.FC<SlotControlsTabsProps> = (props) => {
  if (!props.canvaTemplate || !props.canvaTemplate.designData) {
    return <></>;
  }
  const [queryParams, setQueryParams] = useQueryParams();

  const designData: TemplateCanvaObj = props.canvaTemplate.designData;

  const selectedTab = queryParams.tab || 'slot-config';
  const [currentTab, handleTabChange] = React.useState<string>(selectedTab);

  const allTabs: TabsElementsMap = {
    'slot-config': (
      <SlotConfiguration
        front={designData?.front}
        back={designData?.back!}
        templateId={props.templateId}
        updateSlotValues={props.updateSlotValues}
      />
    ),
    'slot-exceptions': (
      <ExceptionsPage
        garmentColors={props.garmentColors}
        hideExceptions={props.canvaTemplate.hideExceptions}
        onUpdateTemplate={props.onUpdateTemplate}
        isUpdating={props.isSaving}
      />
    ),
    'linked-slots': (
      <LinkedSlots
        garmentColors={props.garmentColors}
        slots={props.slotsOrder}
        frontSlots={designData?.front}
        backSlots={designData?.back!}
      />
    ),
    'slots-presets': <>Tab 4 Prueba</>,
    walkthrough: (
      <WalkthroughTapSection
        templateId={props.canvaTemplate.id}
        designData={designData}
      />
    ),
    stickers: <StickersPage type={designData.type} />,
    'negative-area': <>Tab 6 Prueba</>,
  };

  const TabsNewFormat = [
    {
      name: 'Slot Configuration',
      key: 'slot-config',
    },
    {
      name: 'Slot Exceptions',
      key: 'slot-exceptions',
    },
    {
      name: 'Linked Slots',
      key: 'linked-slots',
    },
    {
      name: 'Walkthrough',
      key: 'walkthrough',
    },
    {
      name: 'Stickers',
      key: 'stickers',
    },
  ];

  return (
    <div className="slot-controls-tabs">
      <Row>
        <Col sm={12}>
          <GFXTabs
            initialTab={currentTab}
            tabs={TabsNewFormat}
            onTabClick={(key) => {
              setQueryParams({tab: key});
              handleTabChange(key);
            }}
          />
        </Col>
      </Row>
      <div className="current-slot-control">{allTabs[currentTab]}</div>
    </div>
  );
};

export default SlotControlsTabs;
