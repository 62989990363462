import React from 'react';
import {IBuilderSlots, SideCanva} from '@gfxco/contracts';
import EditSlotModal from './EditSlotModal';
import SlotItem from '../../components/SlotItem';
import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';

import './SlotConfigurationList.scss';

type SlotConfigurationOptionalProps = {};
type SlotConfigurationRequiredProps = {
  front: SideCanva;
  back: SideCanva;
  templateId: string | number;
  updateSlotValues: (slotId: number | string, data: IBuilderSlots) => void;
};

type SlotConfigurationProps = Partial<SlotConfigurationOptionalProps> &
  SlotConfigurationRequiredProps;

const SlotConfiguration: React.FC<SlotConfigurationProps> = (props) => {
  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = React.useState<IBuilderSlots | null>(
    null,
  );
  if (!props.front && !props.back) return <h2>No Slots Found</h2>;
  const renderFront =
    props.front && props.front.objects && props.front.objects.length;
  const renderBack =
    props.back && props.back.objects && props.back.objects.length;

  const handleOnEditButton = (slot: IBuilderSlots) => {
    setSelectedSlot(slot);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="slot-configuration-section-tab">
      <div className="slot-list-sections">
        <h2 className="slot-list-title">Front Elements</h2>
        {renderFront ? (
          <>
            <div className="slot-property-columns">
              <div className="slot-property-columns__item">
                <p>Image: </p>
              </div>
              <div className="slot-property-columns__item">
                <p>Slot Type: </p>
              </div>
              <div className="slot-property-columns__item">
                <p>Name: </p>
              </div>
            </div>
            <div className="list">
              {props.front.objects.map((slot, index) => {
                return (
                  <SlotItem
                    key={slot.uuid}
                    index={index + 1}
                    slot={slot}
                    onClickEdit={() => handleOnEditButton(slot)}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <p className="slot-list-sections__no-items">
            {' '}
            No front elements added{' '}
          </p>
        )}
      </div>
      <div className="slot-list-sections">
        <h2 className="slot-list-title">Back Elements</h2>
        {renderBack ? (
          <>
            <div className="slot-property-columns">
              <div className="slot-property-columns__item">
                <p>Image: </p>
              </div>
              <div className="slot-property-columns__item">
                <p>Slot Type: </p>
              </div>
              <div className="slot-property-columns__item">
                <p>Name: </p>
              </div>
            </div>
            <div className="list">
              {props.back.objects.map((slot, index) => {
                return (
                  <SlotItem
                    key={slot.uuid}
                    index={index + 1}
                    slot={slot}
                    onClickEdit={() => handleOnEditButton(slot)}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <p className="slot-list-sections__no-items">
            {' '}
            No back elements added{' '}
          </p>
        )}
      </div>
      {selectedSlot && (
        <EditSlotModal
          isModalOpen={isModalOpen}
          onCloseModal={() => {
            setIsModalOpen(!isModalOpen);
            setSelectedSlot(null);
          }}
          updateSlotValues={props.updateSlotValues}
          templateId={props.templateId}
          selectedSlot={selectedSlot}
          shopId={shopId}
        />
      )}
    </div>
  );
};

export default SlotConfiguration;
