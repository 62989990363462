import React from 'react';
import {
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {ThreeDots} from 'react-bootstrap-icons';
import classNames from 'classnames';
import Icon from '../Icons/Icon';
import {IconMap} from '../Icons/IconMapping';
import './DropdownMenu.scss';

interface ItemOption {
  id: string;
  label: string;
  icon?: keyof typeof IconMap;
  disabled?: boolean;
  tooltip?: string;
}

interface RequiredProps {
  itemOptions: ItemOption[];
}

interface OptionalProps {
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
  onItemClick?: (id: string) => void;
}

export type DropdownMenuProps = RequiredProps & OptionalProps;

const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  const handleItemClick = (id: string) => {
    if (id && props.onItemClick) {
      props.onItemClick(id);
    }
  };

  return (
    <DropdownButton
      className={classNames('DropdownMenu', props.className)}
      title={<ThreeDots />}
      size="sm"
      variant="light"
    >
      {props.itemOptions.map((item) => {
        const dropdownItemElem = (
          <Dropdown.Item
            key={item.id}
            onClick={(event) => {
              event.stopPropagation();
              handleItemClick(item.id);
            }}
            disabled={item.disabled}
          >
            {item.icon && <Icon name={item.icon} />}
            {item.label}
          </Dropdown.Item>
        );

        if (item.tooltip) {
          return (
            <OverlayTrigger
              key={item.id}
              placement="auto"
              delay={{show: 200, hide: 200}}
              overlay={
                item.tooltip ? (
                  <Tooltip className="white-tooltip">{item.tooltip}</Tooltip>
                ) : (
                  <></>
                )
              }
            >
              <div>{dropdownItemElem}</div>
            </OverlayTrigger>
          );
        }

        return dropdownItemElem;
      })}
    </DropdownButton>
  );
};

export default DropdownMenu;
