import {SyntheticEvent} from 'react';

export const IconMap = {
  checkBordered: 'check-bordered-icon.svg',
  xBordered: 'x-bordered-icon.svg',
  undoAction: 'undo_action.svg',
  redoAction: 'redo_action.svg',
  backFullScreen: 'back-full-screen.svg',
  centerHorizontally: 'center-horizontally.svg',
  centerVertically: 'center-vertically.svg',
  rotateLeft: 'rotate-left.svg',
  rotateRight: 'rotate-right.svg',
  rotateRestore: 'rotate-restore.svg',
  zoomIn: 'zoom-in.svg',
  zoomOut: 'zoom-out.svg',
  fullScreen: 'full-screen.svg',
  garmentColor: 'garment-color.svg',
  backgroundSlotIcon: 'background-slot-icon.svg',
  backgroundIcon: 'background-icon.svg',
  magnifierIcon: 'magnifier-icon.svg',
  chooseProductType: 'choose-product-type-icon.svg',
  createCustomCanva: 'create-custom-canva-icon.svg',
  createYourDesignTemplate: 'create-your-design-template.svg',
  upArrow: 'arrow-up-icon.svg',
  leftIcon: 'left-icon.svg',
  rightIcon: 'right-icon.svg',
  textSlotIcon: 'text-slot-icon.svg',
  downArrow: 'down-arrow-icon.svg',
  downArrowWhite: 'arrow-down-white-variant-icon.svg',
  profileImage: 'profile-image-icon.svg',
  addImagePlus: 'image-plus-icon.svg',
  camera: 'camera-icon.svg',
  pencil: 'pencil-icon.svg',
  lock: 'lock-icon.svg',
  longDownArrowIcon: 'long-arrow-down-icon.svg',
  longUpArrowIcon: 'long-arrow-up-icon.svg',
  checkIcon: 'check-icon.svg',
  checkIconBlue: 'check-fill-blue-icon.svg',
  infoIcon: 'info-icon.svg',
  info: 'info-grey-icon.svg',
  xIcon: 'x-icon.svg',
  xIconCircle: 'x-circle-icon.svg',
  shipping: 'shipping-icon.svg',
  comment: 'comment-icon.svg',
  settingsIcon: 'setting-icon.svg',
  productIcon: 'product-icon.svg',
  myStoryIcon: 'my-store-icon.svg',
  eyeIcon: 'eye-icon.svg',
  eyeClosedIcon: 'eye-closed-icon.svg',
  cartWithAlert: 'cart-with-alert.svg',
  shopifyIcon: 'shopify-icon.svg',
  flipIcon: 'flip-icon.svg',
  publishIcon: 'publish-icon.svg',
  home: 'home-icon.svg',
  homeFill: 'home-fill-icon.svg',
  homeFillWhite: 'home-fill-white-variant-icon.svg',
  dashboard: 'dashboard-icon.svg',
  product: 'product-icon.svg',
  image: 'image-menu-icon.svg',
  font: 'font-icon.svg',
  label: 'label-icon.svg',
  order: 'order-icon.svg',
  management: 'management-icon.svg',
  moderation: 'moderation-icon.svg',
  samplePortal: 'sample-portal-icon.svg',
  editor: 'editor-icon.svg',
  history: 'history-icon.svg',
  settings: 'setting-icon.svg',
  subscription: 'subscription-icon.svg',
  billing: 'billing-icon.svg',
  user: 'user-menu-icon.svg',
  help: 'help-icon.svg',
  account: 'account-icon.svg',
  signOut: 'sign-out-icon.svg',
  emailConfirmation: 'email-confirmation-icon.svg',
  greeting: 'greeting-icon.svg',
  congratulations: 'congratulations-icon.svg',
  emptyFolder: 'empty-folder-icon.svg',
  folder: 'folder-open-icon.svg',
  duplicate: 'icon_duplicate.svg',
  message: 'message-icon.svg',
  videoPlayer: 'video-player-icon.svg',
  imageNote: 'image-note-icon.svg',
  save: 'save-icon.svg',
  edit: 'edit-icon.svg',
  plusFolder: 'folder-plus-icon.svg',
  stickerPlus: 'sticker-plus-icon.svg',
  arrowDownRight: 'arrow-down-right.svg',
  imageFill: 'image-fill-icon.svg',
  notFound: 'not-found.svg',
  hasLabel: 'has-label-icon.svg',
  notHasLabel: 'not-has-label-icon.svg',
  reload: 'reload-icon.svg',
  separator: 'separator-icon.svg',
  createNewStore: 'create-new-store-icon.svg',
  alignLeft: 'align-left-icon.svg',
  alignCenter: 'align-center-icon.svg',
  alignRight: 'align-right-icon.svg',
  templateConfiguration: 'template-configuration-icon.svg',
  delete: 'delete-icon.svg',
  duplicate2: 'duplicate-icon.svg',
  editDesign: 'edit-design-icon.svg',
  defaultStoreImage: 'store-default-image.svg',
  exclamationCircle: 'exclamation-circle-icon.svg',
  information: 'information-icon.svg',
  printfulLogo: 'printful-logo.svg',
  fifthSunLogo: 'fifth-sun-logo.svg',
  fifthSunLogo2: 'fifth-sun-logo_2.svg',
  notConnected: 'not-connect-icon.svg',
  sendArts: 'send-arts-icon.svg',
  externalLink: 'external-link-icon.svg',
  renderArt: 'render-art.svg',
  arrowRepet: 'arrow-repet-icon.svg',
  imageOutline: 'image-outline-icon.svg',
  scaleIcon: 'scale-icon.svg',
  plusIcon: 'plus-icon.svg',
  lessIcon: 'less-icon.svg',
  printerNotConnected: 'fullfillment-notconnected.svg',
  itemNotFound: 'not-found-icon.svg',
  badgeNewDesign: 'badge-new-design.svg',
  oopsEmpty: 'oops-icon.svg',
  addTemplate: 'add-template-icon.svg',
  publishDesign: 'publish-design-icon.svg',
  fulfillment: 'fulfillment-icon.svg',
  check: 'check-line-icon.svg',
};

interface IconOptionalProps {
  classes: string;
  action: (e: SyntheticEvent) => void;
  box: boolean;
  boxHex?: string;
  fillColor: {default: string; hover?: string; disabled?: string};
  isHovering?: boolean;
  tooltip: string;
  disabled: boolean;
  width: number;
  height: number;
  tooltipPosition?: string;
  onClick: (e: SyntheticEvent) => void;
}

interface IconRequiredProps {
  name: keyof typeof IconMap;
}

export type IconProps = IconRequiredProps & Partial<IconOptionalProps>;
