import React from 'react';
import {Row, Col, InputGroup, Form} from 'react-bootstrap';
import {Search} from 'react-bootstrap-icons';
import debounce from 'lodash.debounce';
import GarmentColor from '../../../../../components/ShareActions/GarmentColor';
import ImageAction from '../../../../../components/ShareActions/ImageAction';
import Tags from '../../../../../components/ShareActions/Tags';
import {IException, IImage, IColor} from '@gfxco/contracts';
import {colors as defaultTextColors} from '../../../../../libs/getDefaultTextColors';
import './ExceptionsBox.scss';

export interface RequiredProps {
  exceptions: IException[];
  onDelete: (item: {tag?: string; color?: IColor; image?: IImage}) => void;
  onSearch: (param: string) => void;
}

export interface OptionalProps {
  isEmpty: boolean;
}

type ExceptionsBoxProps = RequiredProps & OptionalProps;

export const ExceptionsBox: React.FC<ExceptionsBoxProps> = ({
  exceptions,
  onDelete,
  onSearch,
  isEmpty = false,
}) => {
  const tagExceptions = exceptions.filter(({type}) => type === 'tag');
  const imageExceptions = exceptions.filter(({type}) => type === 'image');
  const colorExceptions = exceptions.filter(({type}) => type === 'fillColor');

  return (
    <div id="ExceptionsBox">
      <div
        className={`exceptions-container ${
          !isEmpty ? 'empty-exceptions-container ' : ''
        }`}
      >
        {!isEmpty && (
          <span className="exceptions-empty-text">
            No exception elements added
          </span>
        )}
        {isEmpty && (
          <div>
            <div className="list-toolbar">
              <InputGroup className="search-bar-group search-bar-list">
                <InputGroup.Text>
                  <Search />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search by name"
                  onChange={debounce((event) => {
                    onSearch(event.target.value.toLowerCase());
                  }, 500)}
                  aria-label="SearchBar to filter by name"
                />
              </InputGroup>
            </div>

            <div className="exceptions-selected">
              <Row>
                {!!tagExceptions.length &&
                  tagExceptions.map((exception, index) => (
                    <Col
                      md="auto"
                      className="exception-item"
                      key={`tag-selected-${index}`}
                    >
                      {exception.tag && (
                        <Tags
                          tagItem={{
                            tag: exception.tag,
                            id: exception.collectionId,
                          }}
                          showDelete
                          onDelete={onDelete}
                        />
                      )}
                    </Col>
                  ))}
                {!!imageExceptions.length &&
                  imageExceptions.map((exception, index) => (
                    <Col
                      md="auto"
                      className="exception-item"
                      key={`image-selected-${index}`}
                    >
                      {exception.image && (
                        <ImageAction
                          name={exception.image?.name}
                          url={exception.image?.imageUrl}
                          id={exception.image.id}
                          showName
                          showDelete
                          onDelete={onDelete}
                        />
                      )}
                    </Col>
                  ))}
                {!!colorExceptions.length &&
                  colorExceptions.map((exception, index) => (
                    <Col
                      md="auto"
                      className="exception-item"
                      key={`exception-selected-${index}`}
                    >
                      {exception.fillColor && (
                        <GarmentColor
                          showTitle={false}
                          color={defaultTextColors.find(
                            (color) => color.hex === exception.fillColor,
                          )}
                          showDelete
                          onDelete={onDelete}
                          showHex={true}
                          showName={false}
                        />
                      )}
                    </Col>
                  ))}
              </Row>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExceptionsBox;
