import React from 'react';

import Icon from '../../../components/Icons/Icon';
import useQueryParams from '../../../hooks/useQueryParams';
import {useNavigate} from 'react-router-dom';
import CustomModal from '../../../components/CustomModal';

import './ConfirmationModal.scss';

const ConfirmationModal: React.FC = () => {
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useQueryParams();
  const [showConfirmation, setShowConfirmation] = React.useState(
    !!queryParams.confirmation,
  );
  const [showFailConfirmation, setShowFailConfirmation] = React.useState(
    !!queryParams.failed,
  );

  const handleOnClose = () => {
    setShowConfirmation(false);
    setShowFailConfirmation(false);
    delete queryParams.confirmation;
    delete queryParams.failed;
    setQueryParams({...queryParams});
  };

  const CongratulationsContent = () => {
    return (
      <>
        <Icon name="congratulations" />

        <span className="confirmation-bold">Congratulations!</span>
        <span>Your account is now synced with gfx,</span>
        <div className="confirmation-text">
          but you need to finish the following steps to complete the setup.
        </div>
        <div>
          If you need help,{' '}
          <a
            href="https://gfx-help.notion.site/Help-Center-1ed49d73504a42f482a9a3257c635fe2"
            target="_blank"
            rel="noreferrer"
          >
            check out our documentation.
          </a>
        </div>
        <button onClick={handleOnClose} className="greeting-button">
          Got it!
        </button>
      </>
    );
  };

  const FailedContent = () => {
    return (
      <>
        <Icon name="emptyFolder" />
        <span className="confirmation-bold">Oops!</span>
        <div className="greeting-text">
          Looks like we missed something, and we couldn&apos;t connect your
          store but don&apos;t worry, let&apos;s fix it!
        </div>
        {queryParams.store && (
          <button
            onClick={() => navigate(`/connect?store=${queryParams.store}`)}
            className="greeting-button"
          >
            Sync my store
          </button>
        )}
      </>
    );
  };

  return (
    <div id="Confirmation">
      <CustomModal
        sizeType="middle-page"
        modalTile=""
        show={showConfirmation || showFailConfirmation}
        variant="styled"
      >
        <div id="ConfirmationModal">
          {showFailConfirmation ? (
            <FailedContent />
          ) : (
            <CongratulationsContent />
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default ConfirmationModal;
