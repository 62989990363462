import React, {SyntheticEvent} from 'react';
import {XLg} from 'react-bootstrap-icons';
import {IImage} from '@gfxco/contracts';
import Form from 'react-bootstrap/Form';
import {ReactComponent as LinkIcon} from '../../../../assets/icons/link-icon.svg';
import {ReactComponent as EditIcon} from '../../../../assets/icons/edit-icon.svg';
import Loading from '../../../Loading';

import './Preview.scss';

interface RequiredProps {
  identifier: string;
  name: string;
  type: 'images' | 'collections';
  onClick: (key: string, id?: number) => void;
  id: number;
}

interface OptionalProps {
  thumbnail?: string;
  description?: string;
  isLoading?: boolean;
  imageUrl?: string;
  onEdit?: (data: IImage & {description?: string}) => void;
  onDelete: (id: number) => void;
  listView?: boolean;
  isError?: boolean;
  error?: string;
}

type PreviewProps = RequiredProps & OptionalProps;

const Preview: React.FC<PreviewProps> = ({
  identifier,
  name,
  thumbnail,
  description,
  imageUrl,
  onClick,
  onEdit,
  isLoading,
  isError,
  error,
  id,
  type,
  listView = false,
  onDelete,
}) => {
  const [showCopyConfirmation, setShowCopyConfirmation] =
    React.useState<boolean>(false);

  const [isEditingName, setIsEditingName] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState<string>(name);

  const ImageUploadFailed = () => (
    <div className="error-box">
      <div className="error-message">
        <span className="error-message-title">ERROR!</span>
        <span className="error-message-text">
          {error || 'Something went wrong.'}
        </span>
      </div>
    </div>
  );

  // Temporary set original image if thumbnail is not ready yet.
  const handleUnloadedImage = (e: SyntheticEvent<HTMLImageElement>) => {
    const target = e.target as HTMLImageElement;
    target.src = imageUrl || '';
  };

  const handleCopy = (text?: string) => {
    if (text) {
      navigator.clipboard.writeText(text);
      setShowCopyConfirmation(true);
      setTimeout(() => {
        setShowCopyConfirmation(false);
      }, 3000);
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyPressed = event.key;

    if (keyPressed === 'Enter' && id && inputValue && onEdit) {
      const newName = inputValue.trim();
      onEdit({id, name: newName});
      setIsEditingName(false);
    }
  };

  const handleOnEdit = () => {
    if (type === 'images') {
      setIsEditingName(true);
    } else {
      if (onEdit) onEdit({id, name, description, imageUrl});
    }
  };

  return (
    <>
      <div
        id={`PreviewImage-${identifier}`}
        className={`Preview__Card ${listView ? 'Preview__ListCard' : ''}`}
      >
        {isError && <ImageUploadFailed />}
        {isLoading && (
          <div className="warning">
            <Loading variant="dark" spinnerOnly />
          </div>
        )}
        {!isLoading && !isError && (
          <img
            loading="lazy"
            width={listView ? '2.1rem' : '17rem'}
            height={listView ? '2.2rem' : '15rem'}
            src={thumbnail}
            onError={handleUnloadedImage}
            onClick={() => onClick(name, id)}
          />
        )}
        <div className={`Preview__Text ${listView ? 'Preview__ListText' : ''}`}>
          {(!isEditingName || type === 'collections') && <span>{name}</span>}
          {isEditingName && type === 'images' && (
            <Form.Control
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleOnKeyDown}
              aria-label="input name"
            />
          )}
        </div>
        <div
          id={`PreviewActions-${identifier}`}
          className={`Preview__ActionButtons ${
            listView ? 'Preview__ListActionButtons' : ''
          }`}
        >
          {type === 'images' && (
            <button
              className="button-actions button-tooltip"
              data-tooltip="Copy image link"
              onClick={() => handleCopy(thumbnail)}
            >
              <LinkIcon />
            </button>
          )}
          <button
            className="button-actions button-tooltip"
            onClick={() => handleOnEdit()}
            data-tooltip={`Edit ${type === 'images' ? 'Image' : 'Collection'}`}
          >
            <EditIcon />
          </button>
          <button
            className="icon-fill button-tooltip"
            onClick={() => onDelete(id!)}
            data-tooltip={`Delete ${
              type === 'images' ? 'Image' : 'Collection'
            }`}
          >
            <XLg />
          </button>
        </div>
        {showCopyConfirmation && (
          <div className="preview-tooltip">
            <span>URL Copied!</span>
          </div>
        )}
      </div>
    </>
  );
};

export default Preview;
