import React from 'react';
import classNames from 'classnames';
import {ReactComponent as PrinterConnectedIcon} from '../../../assets/icons/printer-connected-icon.svg';
import {ReactComponent as PrinterDisconnectedIcon} from '../../../assets/icons/printer-disconnected-icon.svg';
import {ReactComponent as PrinterErrorIcon} from '../../../assets/icons/printer-error-icon.svg';
import {ReactComponent as WebhookConnectedIcon} from '../../../assets/icons/webhook-connected-icon.svg';
import {ReactComponent as WebhookDisconnectedIcon} from '../../../assets/icons/webhook-notconnected-icon.svg';
import {ReactComponent as WebhookTestIcon} from '../../../assets/icons/webhook-test.svg';
import Loading from '../../../components/Loading';

interface IFulfillmentServiceStatusProps {
  loading?: boolean;
  tested?: boolean;
  errored?: boolean;
  type?: 'printer' | 'webhook';
  connected?: boolean;
  className?: string;
}

const FulfillmentServiceStatus: React.FC<IFulfillmentServiceStatusProps> = ({
  loading,
  tested,
  errored,
  connected,
  type = 'printer',
  className = '',
}) => {
  const statusMap = {
    printer: {
      default: {
        icon: <PrinterDisconnectedIcon />,
        message: (
          <span>
            Please complete all information before connecting your printer.
          </span>
        ),
      },
      connected: {
        icon: <PrinterConnectedIcon />,
        message: (
          <span>
            Your printer has been <b>connected</b> successfully
          </span>
        ),
      },
      error: {
        icon: <PrinterErrorIcon />,
        message: (
          <span>
            Oops... Something went wrong. Please check your information and try
            again.
          </span>
        ),
      },
      tested: {
        icon: <PrinterDisconnectedIcon />,
        message: <span></span>,
      },
    },
    webhook: {
      default: {
        icon: <WebhookDisconnectedIcon />,
        message: (
          <span>
            Please complete all the information before trying to send the
            webhook.
          </span>
        ),
      },
      connected: {
        icon: <WebhookConnectedIcon />,
        message: (
          <span>
            Your webhook has been <b>connected</b> successfully
          </span>
        ),
      },
      error: {
        icon: <PrinterErrorIcon />,
        message: (
          <span>
            oops... something went wrong, please check your information and try
            again
          </span>
        ),
      },
      tested: {
        icon: <WebhookTestIcon />,
        message: (
          <span>
            Your test Webhook has been <b>sent</b> successfully
          </span>
        ),
      },
    },
  };

  const getStatusFragment = () => {
    if (connected) {
      return statusMap[type].connected;
    }
    if (errored) {
      return statusMap[type].error;
    }
    if (tested) {
      return statusMap[type].tested;
    }
    return statusMap[type].default;
  };

  const {icon, message} = getStatusFragment();

  return (
    <div className={classNames('FulfillmentServiceStatus', className)}>
      <div
        className={classNames('integration-status-box', {
          'integration-status-box--connected': connected,
          'integration-status-box--tested': tested,
        })}
      >
        {loading && <Loading variant="primary" spinnerOnly />}
        {!loading && (
          <>
            {icon}
            {message}
          </>
        )}
      </div>
    </div>
  );
};

export default FulfillmentServiceStatus;
