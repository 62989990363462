import React from 'react';

import classNames from 'classnames';
import Icon from '../../../components/Icons/Icon';
import './StepItemLine.scss';

interface RequiredProps {
  steps: {name: string; status: 'completed' | 'not-completed'}[];
}

const StepItem: React.FC<RequiredProps> = ({steps}) => {
  return (
    <div className="step-item-number">
      {steps.map((s, index) => (
        <>
          <div
            className={classNames('step-item-border', {
              'step-item-border--completed': s.status === 'completed',
            })}
          >
            <div
              className={classNames('step-item-index', {
                'step-item-index--completed': s.status === 'completed',
              })}
            >
              {s.status === 'completed' ? <Icon name="check" /> : s.name}
            </div>
          </div>
          {steps.length > index + 1 && (
            <hr
              className={classNames('step-item-line', {
                'step-item-line--completed': s.status === 'completed',
              })}
            />
          )}
        </>
      ))}
    </div>
  );
};

export default StepItem;
