import React from 'react';

import './HelpBoxItem.scss';
import GFXImage from '../../../components/Image/Image';
import GfxDocumentationImage from '../../../assets/gfx_documentation_image.png';
import GfxEmailImage from '../../../assets/gfx_email_image.png';
import GfxTemplateImage from '../../../assets/gfx_home_template.png';

interface RequiredProps {
  title: string;
  description: string | React.ReactNode;
  image?: 'email' | 'template' | 'documentation';
}

const HelpBoxItem: React.FC<RequiredProps> = ({
  title,
  description,
  image = 'template',
}) => {
  const src =
    image === 'template'
      ? GfxTemplateImage
      : image === 'documentation'
      ? GfxDocumentationImage
      : GfxEmailImage;

  return (
    <div className="home-box-item">
      <div className="home-box-text">
        <span className="box-text-title">{title}</span>
        <span className="box-text-description">{description}</span>
      </div>
      <GFXImage src={src} placeholderSrc="/loading-render.gif" />
    </div>
  );
};

export default HelpBoxItem;
