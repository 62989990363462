import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import debounce from 'lodash.debounce';
import {SESSION_STORAGE_KEYS} from '../../constants';

// Components
import ShopifyIntegrationSection from './panels/ShopifyIntegrationSection';
import StoreSection from './panels/StoreSection';
import GFXButton from '../../components/Button';
import Icon from '../../components/Icons/Icon';
import GFXStoreItemComponent from '../../components/GFXStoreItemComponent/GFXStoreItemComponent';
import {Form, InputGroup} from 'react-bootstrap';
import {Search} from 'react-bootstrap-icons';
import CreateNewStoreForm from './CreateNewStoreForm/CreateNewStoreForm';
import CustomModal from '../../components/CustomModal';
import GFXCheckbox from '../../components/GFXCheckbox';

// Hooks
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {selectShops, deleteShopAsync} from '../../features/shops/loadShops';
import {
  selectShop,
  updateSelectedShop,
} from '../../features/shops/selectedShop';
import {selectUserPermissions} from '../../features/user';
import useSessionStorage from '../../hooks/useSessionStorage';

// Contracts
import {GetShopsResponse, constants} from '@gfxco/contracts';

// Data
import {ColorOptions} from './storeColorOptions.data';

import './MyStorePage.scss';
import {GFXToastLaunch} from '../../components/ToastMessage/ToastMessage';

const MyStorePage: React.FC = () => {
  const [shopsAvailable, setShopsAvailable] = useState<GetShopsResponse[]>([]);
  const [shopFilter, setShopFilter] = useState<string>('');
  const [isUnderstandChecked, setIsUnderstandChecked] =
    useState<boolean>(false);
  const [showNewStoreModal, setShowNewStoreModal] = useState<boolean>(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    useState<boolean>(false);
  const [selectedShopForDelete, setSelectedShopForDelete] = useState<number>();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const {setValue, removeValue} = useSessionStorage();
  const shops = useAppSelector(selectShops);
  const shopSelected = useAppSelector(selectShop);
  const userPermissions = useAppSelector(selectUserPermissions);

  useEffect(() => {
    if (searchParams.get('newStore') === 'true') {
      setShowNewStoreModal(true);
    } else {
      setShowNewStoreModal(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (shops) {
      const shopsFiltered = shops.filter(
        (shop) =>
          shopFilter === '' ||
          shop.name.toLowerCase().includes(shopFilter.toLowerCase()),
      );
      setShopsAvailable(shopsFiltered);
    }
  }, [shops, shopFilter]);

  const openNewStoreModal = (show: boolean) => {
    setSearchParams({newStore: show.toString()});
  };

  const handleDeleteStoreClick = (shopId: number) => {
    setSelectedShopForDelete(shopId);
    setShowConfirmDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setSelectedShopForDelete(undefined);
    setIsUnderstandChecked(false);
  };

  const handleDeleteStore = async (shopId: number) => {
    const shopDeleted = shopsAvailable.find((shop) => shop.id === shopId);
    if (!shopDeleted) {
      return;
    }

    await dispatch(deleteShopAsync({shopId}));
    GFXToastLaunch(
      <p>
        Your store, &quot;{shopDeleted.name}&quot;,{' '}
        <span className="highligh-text">has been deleted</span>
      </p>,
      5000,
      {
        showAt: 'bottom',
        showIcon: true,
        alertType: 'success',
      },
    );

    if (shopSelected && shopId === shopSelected.id) {
      removeValue(SESSION_STORAGE_KEYS.SELECTED_SHOP);
      dispatch(updateSelectedShop(undefined));
      return;
    }

    handleCloseDeleteModal();
  };

  if (!shopSelected) {
    return <></>;
  }

  const isOwnerInMultipleStores = shops
    ? shops.filter((s) => s.is_owner).length > 1
    : false;

  return (
    <div id="MyStorePage" className="MyStorePage">
      <header className="MyStorePage__header">
        <div className="store-header">
          <div className="store-header__title">
            <h3>My Store</h3>
            <p>Manage your store(s) and their configuration.</p>
          </div>

          {userPermissions &&
            userPermissions.includes(constants.USER_PERMISSIONS.ALL) && (
              <div className="store-header__callToAction">
                <GFXButton
                  variant="primary"
                  className="store-header__callToAction__button"
                  onClick={() => {
                    openNewStoreModal(true);
                  }}
                >
                  <div style={{display: 'flex'}}>
                    <Icon name="createNewStore" />
                    <span style={{alignSelf: 'center'}}>
                      Create a new store
                    </span>
                  </div>
                </GFXButton>
              </div>
            )}
        </div>
      </header>

      <main className="MyStorePage__main">
        <aside className="section preview-section">
          <div id="MyStorePage__main__search">
            <InputGroup>
              <InputGroup.Text>
                <Search />
              </InputGroup.Text>
              <Form.Control
                className="search-input"
                placeholder="Search"
                aria-label="Search for store"
                onChange={debounce((event) => {
                  setShopFilter(event.target.value);
                }, 500)}
              />
            </InputGroup>
            <GFXButton
              variant="primary"
              className="MyStorePage__main__search__button"
              onClick={() => {
                openNewStoreModal(true);
              }}
            >
              <Icon name="createNewStore" fillColor={{default: '#4573BB'}} />
            </GFXButton>
          </div>
          {shopsAvailable?.map((shop) => (
            <GFXStoreItemComponent
              key={shop.id}
              shop={shop}
              isSelectedStore={shopSelected.id === shop.id}
              onClick={() => {
                if (shopSelected.id !== shop.id) {
                  setValue(
                    SESSION_STORAGE_KEYS.SELECTED_SHOP,
                    shop.id.toString(),
                  );
                  dispatch(updateSelectedShop(shop));
                }
              }}
              isDeleteAvailable={isOwnerInMultipleStores}
              onDeleteStore={handleDeleteStoreClick}
            />
          ))}
        </aside>
        <div className="integrations">
          <StoreSection className="section" />
          <ShopifyIntegrationSection className="section" />
        </div>
      </main>
      <CustomModal
        show={showConfirmDeleteModal}
        addCloseButton={true}
        variant="grey"
        modalTile=""
        sizeType="small-center"
        className="delete-warning-modal"
        saveText="Delete store"
        cancelText="Cancel"
        isSaveEnabled={isUnderstandChecked}
        onClickSave={() => {
          if (selectedShopForDelete) {
            handleDeleteStore(selectedShopForDelete);
          }
        }}
        onCloseModal={handleCloseDeleteModal}
      >
        <div className="delete-warning-header">
          <Icon name="infoIcon" width={60} height={60} />
          <div className="delete-warning-title">
            <h2>Are you sure you want to delete this store?</h2>
            <p>
              This action is irreversible and cannot be undone. If you proceed,
              please consider the following:
            </p>
          </div>
        </div>
        <div className="delete-warning-description">
          <ul>
            <li>
              All templates and images uploaded to this store will be
              permanently deleted.
            </li>
            <li>
              Published products and live previews will no longer be accessible
              and you will need to manually unpublish them in your Shopify
              store.
            </li>
            <li>
              You will lose the ability to manage &quot;in progress&quot; orders
              or receive new orders for your created templates.
            </li>
            <li>
              All &quot;printing&quot; orders will continue with the printing
              and fulfillment process as normal.
            </li>
          </ul>
        </div>
        <GFXCheckbox
          id="delete-warning-checkbox"
          checked={isUnderstandChecked}
          onChange={() => setIsUnderstandChecked((prev: boolean) => !prev)}
          className="order-checkbox"
          label="I acknowledge the repercussions of deleting my store"
        />
      </CustomModal>
      <CreateNewStoreForm
        show={showNewStoreModal}
        onClose={() => {
          openNewStoreModal(false);
        }}
        colorOptions={ColorOptions}
      />
    </div>
  );
};

export default MyStorePage;
