import React from 'react';
import Modal, {ModalProps} from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import './CustomModal.scss';

interface RequiredProps {
  children: React.ReactNode;
  modalTile: string;
  variant: 'grey' | 'blue-grey' | 'styled' | 'default';
  sizeType: 'middle-page' | 'small-center';
}

interface OptionalProps {
  show?: boolean;
  onCloseModal?: () => void;
  isSaveEnabled?: boolean;
  onClickSave?: () => void;
  isProgressWarningEnabled?: boolean;
  className?: string;
  progressWarningTitle?: string;
  progressWarningMessage?: string;
  saveText?: string;
  cancelText?: string;
  addCloseButton?: boolean;
  id?: string;
}

type CustomModalProps = RequiredProps &
  OptionalProps &
  Pick<ModalProps, 'fullscreen'>;

const CustomModal: React.FC<CustomModalProps> = (props) => {
  const {
    onCloseModal,
    isProgressWarningEnabled = false,
    show,
    isSaveEnabled,
    onClickSave,
    saveText = 'Save',
    cancelText,
    addCloseButton = true,
    sizeType,
    variant,
    id,
  } = props;

  const [showProgressWarning, setShowProgressWarning] = React.useState(false);
  const [ignoreWarning, setIgnoreWarning] = React.useState(false);
  const [saveStatus, setSaveStatus] = React.useState('iddle');

  React.useEffect(() => {
    if (ignoreWarning) {
      handleCloseModal();
    }
  }, [ignoreWarning]);

  const handleSaveButtonClick = async () => {
    if (onClickSave && isSaveEnabled) {
      setSaveStatus('loading');
      try {
        await onClickSave();
        setSaveStatus('success');
        setShowProgressWarning(false);
      } catch (error) {
        setSaveStatus('errored');
        console.error('Error saving', error);
      }
    }
  };

  const handleCloseModal = () => {
    if (saveStatus === 'loading') {
      return;
    }

    if (isProgressWarningEnabled && !ignoreWarning) {
      setShowProgressWarning(true);
      return;
    }

    setIgnoreWarning(false);
    if (onCloseModal) {
      onCloseModal();
    }
  };

  return (
    <>
      <Modal
        id={id}
        className={`CustomModal CustomModal-${variant} CustomModal-${sizeType} modal-full-page ${
          props.className || ''
        }`}
        backdropClassName={`CustomModal-backdrop modal-full-page-backdrop ${
          props.className ? `${props.className}-backdrop` : ''
        }`}
        aria-labelledby={`modal-full-page ${props.className || ''}`}
        show={show}
        onHide={handleCloseModal}
        size="lg"
      >
        {addCloseButton && <Modal.Header closeButton></Modal.Header>}

        <Modal.Body className="CustomModal-body">
          {props.modalTile && (
            <div className="modal-header-container">
              <h1 className="modal-title">{props.modalTile}</h1>
              <div className="shadow-divider"></div>
            </div>
          )}
          {props.children}
        </Modal.Body>
        {onClickSave && (
          <Modal.Footer>
            {cancelText && (
              <Button
                variant="link"
                className="custom-modal-cancel-button"
                disabled={saveStatus === 'loading'}
                onClick={handleCloseModal}
              >
                {saveStatus === 'loading' ? 'Loading...' : cancelText}
              </Button>
            )}
            <Button
              variant={isSaveEnabled ? 'primary' : 'secondary'}
              className="custom-modal-save-button"
              disabled={!isSaveEnabled || saveStatus === 'loading'}
              onClick={handleSaveButtonClick}
            >
              {saveStatus === 'loading' ? 'Loading...' : saveText}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <Modal
        className="ModalWarning"
        backdropClassName="ModalWarning-backdrop"
        aria-labelledby="ModalWarning"
        centered
        show={showProgressWarning}
        onHide={() => setShowProgressWarning(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h1 className="modal-warning-title">{props.progressWarningTitle}</h1>
          <p className="modal-warning-text">{props.progressWarningMessage}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => {
              setIgnoreWarning(true);
              setShowProgressWarning(false);
            }}
          >
            Close without saving
          </Button>
          <Button
            variant="primary"
            onClick={() => setShowProgressWarning(false)}
          >
            Keep editing
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomModal;
