import {
  GetSubscriptionResponse,
  ShopConfigsResponse,
  ELoadingStates,
  IUpdateShopRequest,
  IUpdateShopParams,
  UserManagementData,
  GetShopsResponse,
} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getShopConfigs, updateShop, getShopUsers} from '../../api';
import paymentsApi from '../../api/payments';
import {RootState} from '../../app/store';
import {Auth} from 'aws-amplify';

export interface SelectedShopState {
  value: GetShopsResponse | undefined;
  users: UserManagementData[];
  loadingUsers: boolean;
  isLoadingUsersError: boolean;
  configs: ShopConfigsResponse | undefined;
  configsStatus: ELoadingStates;
  subscription: GetSubscriptionResponse | undefined;
  subscriptionStatus: ELoadingStates;
  updatingStatus: ELoadingStates;
}

const initialState: SelectedShopState = {
  value: undefined,
  users: [],
  loadingUsers: true,
  isLoadingUsersError: false,
  configs: undefined,
  configsStatus: ELoadingStates.IDLE,
  subscription: undefined,
  subscriptionStatus: ELoadingStates.IDLE,
  updatingStatus: ELoadingStates.IDLE,
};

export const getShopUsersAsync = createAsyncThunk(
  'shopsUsers/fetch',
  async () => {
    const response = await getShopUsers();
    // The value we return becomes the `fulfilled` action payload
    return response?.users || [];
  },
);

export const getShopConfigsAsync = createAsyncThunk(
  'shopsConfigs/fetch',
  async (shopId: number, thunkAPI) => {
    let response = await getShopConfigs(shopId);

    // If the shop_configs table doesn't have a stripeCustomerId, create a customer
    if (response && ['', undefined, null].includes(response.stripeCustomerId)) {
      const user = await Auth.currentAuthenticatedUser();
      const state = thunkAPI.getState() as RootState;
      await thunkAPI.dispatch(
        postCreateCustomerAsync({
          shopId,
          email: user.attributes.email,
          shopName: state.selectedShop.value?.name || '',
        }),
      );
      // Refetch the shop configs after creating the customer
      response = await getShopConfigs(shopId);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const getShopSubscriptionsAsync = createAsyncThunk(
  'shopsSubscriptions/fetch',
  async (shopId: number) => {
    const response = await paymentsApi.getSubscription(shopId);
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const postCreateCustomerAsync = createAsyncThunk(
  'shopCustomer/create',
  async ({
    shopId,
    email,
    shopName,
  }: {
    shopId: number;
    email: string;
    shopName: string;
  }) => {
    const response = await paymentsApi.createCustomer(shopId, email, shopName);
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteCancelSubscriptionAsync = createAsyncThunk(
  'shopSubscription/cancel',
  async (shopId: number, thunkAPI) => {
    const response = await paymentsApi.cancelSubscription(shopId);

    if (response) {
      await thunkAPI.dispatch(getShopConfigsAsync(shopId));
      await thunkAPI.dispatch(getShopSubscriptionsAsync(shopId));
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const postCreateSubscriptionAsync = createAsyncThunk(
  'shopSubscription/create',
  async (paymentMethodId: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const starterServices = state.services.value?.find(
      (service) => service.plan === 'starter',
    );
    const services = starterServices?.services.map(
      (service) => service.priceId,
    );
    const customerId = state.selectedShop.configs?.stripeCustomerId;
    const shopId = state.selectedShop.value?.id;

    if (!services || !customerId || !shopId) {
      return;
    }

    const response = await paymentsApi.createSubscription(
      paymentMethodId,
      customerId,
      services,
    );

    if (response && shopId) {
      await thunkAPI.dispatch(getShopConfigsAsync(shopId));
      await thunkAPI.dispatch(getShopSubscriptionsAsync(shopId));
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const updateShopAsync = createAsyncThunk(
  'shop/update',
  async (parameters: IUpdateShopRequest & IUpdateShopParams) => {
    const response = await updateShop(parameters);
    return response;
  },
);

export const selectedShop = createSlice({
  name: 'selectedShop',
  initialState,
  reducers: {
    updateSelectedShop: (state, action) => {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShopConfigsAsync.fulfilled, (state, action) => {
        state.configs = action.payload;
        state.configsStatus = ELoadingStates.LOADED;
      })
      .addCase(getShopConfigsAsync.rejected, (state) => {
        state.configs = undefined;
        state.configsStatus = ELoadingStates.FAILED;
      })
      .addCase(getShopConfigsAsync.pending, (state) => {
        state.configs = undefined;
        state.configsStatus = ELoadingStates.LOADING;
      })
      .addCase(getShopSubscriptionsAsync.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.subscriptionStatus = ELoadingStates.LOADED;
      })
      .addCase(getShopSubscriptionsAsync.rejected, (state) => {
        state.subscription = undefined;
        state.subscriptionStatus = ELoadingStates.FAILED;
      })
      .addCase(getShopSubscriptionsAsync.pending, (state) => {
        state.subscription = undefined;
        state.subscriptionStatus = ELoadingStates.LOADING;
      })
      .addCase(updateShopAsync.fulfilled, (state, action) => {
        if (action.payload && action.payload[0]) {
          state.value = action.payload[0];
        }
        state.updatingStatus = ELoadingStates.LOADED;
      })
      .addCase(updateShopAsync.rejected, (state) => {
        state.updatingStatus = ELoadingStates.FAILED;
      })
      .addCase(updateShopAsync.pending, (state) => {
        state.updatingStatus = ELoadingStates.LOADING;
      })
      .addCase(getShopUsersAsync.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loadingUsers = false;
        state.isLoadingUsersError = false;
      })
      .addCase(getShopUsersAsync.rejected, (state) => {
        state.loadingUsers = false;
        state.isLoadingUsersError = true;
        state.users = [];
      })
      .addCase(getShopUsersAsync.pending, (state) => {
        state.loadingUsers = true;
        state.isLoadingUsersError = false;
        state.users = [];
      });
  },
});

export const selectShop = (state: RootState) => {
  return state.selectedShop.value;
};

export const selectShopConfigs = (state: RootState) => {
  return state.selectedShop.configs;
};

export const selectShopSubscription = (state: RootState) => {
  return state.selectedShop.subscription;
};

export const selectUpdatingStatus = (state: RootState) => {
  return state.selectedShop.updatingStatus;
};

export const selectShopUsers = (state: RootState) => {
  return state.selectedShop.users;
};

export const {updateSelectedShop} = selectedShop.actions;

export default selectedShop.reducer;
