import React from 'react';
import {
  ESlotTypes,
  IBuilderSlots,
  ICollectionResponse,
  ICollectionsRequest,
  IImage,
  ISlotCollection,
  ISlotEditType,
} from '@gfxco/contracts';
import {Form} from 'react-bootstrap';
import Modal from '../../../components/CustomModal';
import CustomizableConfigs from '../../../components/EditSlotComponents/CustomizableConfigs';
import SlotProperties from '../../../components/EditSlotComponents/SlotProperties';
import SelectCollections from '../../../components/SelectCollections';
import DefaultText from '../../../components/EditSlotComponents/DefaultText';
import SlotsApi, {IUpdateSlotPayload} from '../../../api/slots';
import ImagesApi from '../../../api/images';
import {Pencil} from 'react-bootstrap-icons';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {
  getCollectionsAsync,
  selectCollections,
} from '../../../features/collections/loadCollectionsDetails';
import {selectShop} from '../../../features/shops/selectedShop';
import './EditSlotModal.scss';
import {GFXToastLaunch} from '../../../components/ToastMessage/ToastMessage';

type ICollectionType = ICollectionResponse & {blocked?: boolean};

type SlotEditModalOptionalProps = {
  shopCollections: ICollectionResponse[];
  shopId: number;
};

type SlotEditModalRequiredProps = {
  selectedSlot: IBuilderSlots;
  isModalOpen: boolean;
  onCloseModal: () => void;
  templateId: string | number;
  updateSlotValues: (slotId: number | string, data: IBuilderSlots) => void;
};

type FormValues = {
  alias: string;
  Removable?: boolean;
  Movable?: boolean;
  Swappable?: boolean;
  EnableSearch?: boolean;
  CycleOnly?: boolean;
  Customizable?: boolean;
  Negative?: boolean;
  NegativeArea?: boolean;
  DefaultText?: string;
  CustomizationCheck?: boolean;
  RemoveBackground?: boolean;
  AutoCropFace?: boolean;
  FitContainer?: boolean;
  ScalableImage?: boolean;
  GrayScale?: boolean;
  ModerationStage?: string;
  CustomizationMessage?: string;
  MaxLength?: number;
  Alphanumeric?: boolean;
  ForceCapitalize?: boolean;
  DynamicColor?: boolean;
  TextColors?: string[];
};

type SlotEditModalProps = Partial<SlotEditModalOptionalProps> &
  SlotEditModalRequiredProps;

function getSlotType(slot: IBuilderSlots) {
  if (slot?.type === ESlotTypes.Image && slot.isBackground) {
    return ESlotTypes.Background;
  } else if (slot?.type === ESlotTypes.Image) {
    return ESlotTypes.Image;
  } else {
    return ESlotTypes.Text;
  }
}

const EditSlotModal: React.FC<SlotEditModalProps> = (props) => {
  const {isModalOpen, onCloseModal, selectedSlot} = props;

  const slotInternals = selectedSlot?.objects && selectedSlot.objects[0];
  const alias = slotInternals?.alias || selectedSlot.alias;
  const [currentCollections, setCurrentCollections] = React.useState<
    ICollectionType[]
  >([]);
  const dispatch = useAppDispatch();
  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;
  const [collectionImages, setCollectionImages] = React.useState<IImage[]>([]);
  const [hasPendingChanges, setHasPendingChanges] =
    React.useState<boolean>(false);
  const collections = useAppSelector(selectCollections);
  const slotType = getSlotType(slotInternals!);
  const [nativeCollectionLoaded, setNativeCollectionLoaded] =
    React.useState<boolean>(false);
  const [currentDefaultImage, setCurrentDefaultImage] = React.useState<
    Partial<IImage>
  >({
    largeImageUrl: slotInternals?.src,
    thumbnail: slotInternals?.src,
    imageUrl: slotInternals?.src,
  });

  const [defaultImageLoaded, setDefaultImageLoaded] =
    React.useState<Partial<IImage>>();
  const selectedColors = selectedSlot.textColors?.allowedColors || [];

  const swappableOptions = ['swap', 'swappable', 'customizableAndSwappable'];

  const customizableOptions = ['customizable', 'customizableAndSwappable'];

  const [formValues, setFormValues] = React.useState<FormValues>({
    alias: alias!,
    Removable: selectedSlot?.removable,
    Movable: selectedSlot?.moveable,
    CycleOnly: selectedSlot?.cycleOnly,
    Customizable:
      selectedSlot?.customizable ||
      customizableOptions.includes(selectedSlot?.editType || ''),
    Negative: selectedSlot?.negative, // texts
    NegativeArea: selectedSlot?.negative || false, // images slot
    CustomizationCheck: selectedSlot?.customizationRequired,
    RemoveBackground: selectedSlot?.backgroundRemoval,
    AutoCropFace: selectedSlot?.autoCropFace,
    FitContainer: selectedSlot?.fitToContainer ?? true,
    ScalableImage: selectedSlot?.imageScalable,
    DefaultText: slotInternals?.text,
    EnableSearch: selectedSlot?.searchable,
    ModerationStage: selectedSlot?.moderationStage || 'modifying',
    Swappable:
      selectedSlot?.swappable ||
      swappableOptions.includes(selectedSlot?.editType || ''),
    MaxLength: selectedSlot?.maxLength || slotInternals?.text?.length || 10,
    DynamicColor: selectedSlot.textColors?.dynamicColor || false,
    ForceCapitalize: selectedSlot?.forceCapitalization,
    Alphanumeric: selectedSlot?.forceAlphanumericOnly,
    GrayScale: !!selectedSlot?.imageFilters?.grayscale,
    TextColors: [],
  });

  React.useEffect(() => {
    if (!shopId || !selectedSlot) return;
    const validSlotType =
      slotType === ESlotTypes.Image || slotType === ESlotTypes.Background;
    if (
      validSlotType &&
      selectedSlot.collections &&
      selectedSlot.collections.length > 0 &&
      !nativeCollectionLoaded
    ) {
      if (selectedSlot.shopImageId) {
        loadDefaultImage(selectedSlot.shopImageId)
          .then((data) => {
            setDefaultImageLoaded(data);
          })
          .catch((error) => console.log('Error loading default image', error));
      }

      loadCollections({
        shopId,
      }).then(() => setNativeCollectionLoaded(true));

      return;
    } else {
      loadCollections({
        shopId,
      }).then(() => setNativeCollectionLoaded(true));
    }

    setNativeCollectionLoaded(true);
  }, []);

  React.useEffect(() => {
    loadExistentCollections();
  }, [collections]);

  if (!selectedSlot) return null;

  const loadCollections = async (params: ICollectionsRequest) => {
    return dispatch(getCollectionsAsync({...params, limit: 30}));
  };

  const loadDefaultImage = async (id: number) => {
    return ImagesApi.getImageById(id);
  };

  const loadExistentCollections = () => {
    if (!selectedSlot) return;
    const newCollections = mapCollections();
    if (collections && selectedSlot.collections && newCollections) {
      currentCollections.forEach((collection) => {
        const alreadyExist = newCollections.find(
          (newCollection) => newCollection.id === collection.id,
        );
        if (alreadyExist) return;
        newCollections.push(collection);
      });
      setCurrentCollections(newCollections as ICollectionResponse[]);
    }
  };

  const onClickSave = async () => {
    if (!selectedSlot) return;
    if (hasPendingChanges) {
      return GFXToastLaunch(
        'Please save your changes before continuing',
        2000,
        {
          showIcon: true,
          alertType: 'info',
          showAt: 'top',
          right: '0px',
          top: '-4rem',
          parentContainerId: 'EditSlotModal',
        },
      );
    }
    let generateProofs = false;

    let collections = getCollectionsSelected();
    if (!collections?.length) {
      collections =
        selectedSlot.collections?.map((collection) => ({
          collectionId: collection,
          slotId: selectedSlot.slotId,
          name: '',
        })) || [];
    }

    const internalSlot = selectedSlot.objects && selectedSlot.objects[0];
    const updatedSlot = {...selectedSlot};
    if (
      internalSlot &&
      formValues.DefaultText &&
      slotType === ESlotTypes.Text
    ) {
      // if text is different, then generate proofs
      if (internalSlot.text !== formValues.DefaultText) {
        generateProofs = true;
      }

      internalSlot.alias = formValues.alias;
      internalSlot.text = formValues.DefaultText || internalSlot.text;
      updatedSlot.objects = [internalSlot];
    }

    if (slotInternals?.src !== currentDefaultImage.thumbnail) {
      generateProofs = true;
    }

    const slot = {
      id: +selectedSlot?.slotId!,
      alias: formValues.alias,
      templateId: +props.templateId,
      updatedAt: new Date(),
      type: selectedSlot.type as ESlotTypes,
      editType: formValues.Customizable
        ? 'customizable'
        : ('fixed' as ISlotEditType),
      backgroundRemoval: formValues.RemoveBackground,
      cycleOnly: formValues.CycleOnly,
      fitToContainer: formValues.FitContainer,
      moderationStage: formValues.ModerationStage,
      moveable: formValues.Movable,
      autoCropFace: formValues.AutoCropFace,
      categoryNavigation: false,
      searchable: formValues.EnableSearch,
      removable: formValues.Removable,
      imageScalable: formValues.ScalableImage,
      customizationRequired: formValues.CustomizationCheck,
      src: currentDefaultImage.thumbnail || slotInternals?.src,
      image: currentDefaultImage,
      negative: formValues.Negative,
      customizable: formValues.Customizable,
      swappable: formValues.Swappable,
      maxLength: formValues.MaxLength,
      designData: {
        shopImageId: updatedSlot.shopImageId,
        objects: [
          {
            text: updatedSlot.objects?.[0]?.text || '',
            uuid: updatedSlot.objects?.[0]?.uuid || '',
            alias: updatedSlot.objects?.[0]?.alias || '',
            type: updatedSlot.objects?.[0]?.type || '',
          },
        ],
      },
      customizationRequiredMessage: formValues.CustomizationMessage,
      forceAlphanumericOnly: formValues.Alphanumeric || false,
      forceCapitalization: formValues.ForceCapitalize,
      imageFilters: {
        grayscale: formValues.GrayScale,
      },
      textColors: selectedColors,
      dynamicColor: formValues.DynamicColor,
      collections: collections as ISlotCollection[],
    };

    if (!formValues.GrayScale) {
      delete slot.imageFilters.grayscale;
    }

    const payload: IUpdateSlotPayload = {
      slot,
      shopId: props.shopId!.toString(),
      generateProofs,
    };
    try {
      await SlotsApi.updateSlotsById(
        {
          templateId: +props.templateId,
          slotId: +selectedSlot?.slotId!,
        },
        payload,
      );
      props.updateSlotValues(selectedSlot?.slotId!, {
        ...selectedSlot,
        ...slot,
        collections: collections?.map((collection) => collection.collectionId),
        textColors: {
          allowedColors: selectedColors,
          dynamicColor: formValues.DynamicColor!,
        },
      });
      onCloseModal();
    } catch (error) {
      console.error(error);
      return GFXToastLaunch(
        'Something went wrong saving changes, please try again',
        2000,
        {
          alertType: 'danger',
          showIcon: true,
          showAt: 'top',
          right: '0px',
          top: '-4rem',
          parentContainerId: 'EditSlotModal',
        },
      );
    }
  };

  const validateCollectionsLoaded = () => {
    if (collections && selectedSlot.collections) {
      const loadedCollections = selectedSlot.collections;
      const newCollections = collections.filter((c) => {
        if (loadedCollections.includes(c.id)) return true;

        const isSub = c.subCollections?.filter((s) =>
          loadedCollections.includes(s.id),
        );

        if (isSub?.length) return true;
        return false;
      });

      return newCollections;
    }
  };

  const mapCollections = () => {
    if (collections && selectedSlot.collections) {
      const newCollections = validateCollectionsLoaded();
      const loadedCollections = selectedSlot.collections;
      if (!newCollections) return;

      let selected: any = [];

      newCollections.forEach((c) => {
        const totalSub = c.subCollections?.length;
        const subcols = c.subCollections?.filter((s) =>
          loadedCollections?.includes(s.id),
        );

        let itemMapped = {
          ...c,
          totalSubcollections: totalSub,
          subCollections: subcols,
          blocked: false,
        };

        if (!loadedCollections?.includes(c.id)) {
          itemMapped = {...itemMapped, blocked: true};
        }
        selected = [...selected, itemMapped];
      });
      return selected as ICollectionType[];
    }
    return [];
  };

  const handleOptionsChange = (
    key: string,
    value: boolean | string | number,
  ) => {
    const newValuesAfterRule = {
      alias: formValues.alias,
      [key]: value,
    } as FormValues;

    if (key === 'MaxLength' && +value > 100) {
      return;
    }

    if (
      (key === 'CycleOnly' || key === 'EnableSearch') &&
      !formValues.Swappable
    ) {
      return;
    }

    if (
      (key === 'ForceCapitalize' || key === 'Alphanumeric') &&
      !formValues.Customizable
    ) {
      return;
    }

    if (
      key === 'CustomizationCheck' &&
      value === true &&
      !formValues.Customizable
    ) {
      return;
    }

    if (
      (key === 'GrayScale' || key === 'RemoveBackground') &&
      value === true &&
      !formValues.Customizable
    ) {
      return;
    }

    if (key === 'MaxLength' && +value > 100) {
      return;
    }

    if (key === 'Swappable' && value === false) {
      newValuesAfterRule.EnableSearch = false;
      newValuesAfterRule.CycleOnly = false;
    }

    if (key === 'CycleOnly' && value === true) {
      newValuesAfterRule.Customizable = false;
    }

    if (key === 'Customizable' && value === false) {
      newValuesAfterRule.Alphanumeric = false;
      newValuesAfterRule.ForceCapitalize = false;
      newValuesAfterRule.MaxLength = slotInternals?.text?.length || 10;
    }

    if (key === 'Movable' && value === true) {
      newValuesAfterRule.Negative = false;
      newValuesAfterRule.Customizable = false;
    }

    if (
      (key === 'Negative' && value === true) ||
      (key === 'Customizable' && value === true)
    ) {
      newValuesAfterRule.Movable = false;
    }

    if (key === 'CustomizationCheck' && value === true) {
      newValuesAfterRule.CustomizationMessage =
        'Customize all editable slots to checkout';
    }

    setFormValues({...formValues, ...newValuesAfterRule});
  };

  const getCollectionsSelected = () => {
    let collections: {
      collectionId: number;
      slotId?: number | string;
      name: string;
    }[] = [];

    currentCollections?.forEach((c) => {
      if (c.subCollections) {
        const subs = c.subCollections.map((s) => ({
          collectionId: s.id,
          slotId: selectedSlot.slotId,
          name: s.name,
        }));
        collections = [...collections, ...subs];
      }

      if (!c.blocked) {
        collections.push({
          collectionId: c.id,
          slotId: selectedSlot.slotId,
          name: c.name,
        });
      }
    });
    return collections;
  };

  const onDeleteCurrentCollection = (collectionId: number, subId?: number) => {
    let newCollections = [];
    if (subId) {
      const collection = currentCollections?.find(
        (collection) => collection.id === collectionId,
      );

      const subCollections = collection?.subCollections?.filter(
        (s) => s.id !== subId,
      );

      newCollections = currentCollections?.map((collection) => {
        if (collection.id === collectionId) {
          return {...collection, subCollections};
        }
        return collection;
      });

      if (collection?.blocked && !subCollections?.length) {
        newCollections = currentCollections?.filter(
          (collection) => collection.id !== collectionId,
        );
      }
    } else {
      newCollections = currentCollections?.filter(
        (collection) => collection.id !== collectionId,
      );
    }
    setCurrentCollections(newCollections as ICollectionResponse[]);
    const newCollectionImages = collectionImages.filter(
      (image) => image.collectionId === collectionId,
    );

    setCollectionImages(newCollectionImages);
    if (!newCollections.length) {
      setCurrentImage({
        largeImageUrl: '',
        thumbnail: '',
        imageUrl: '',
      } as IImage);
    }
  };

  const handleSelectedNewCollection = (list: ICollectionResponse[]) => {
    setCurrentCollections(list);
  };

  const setCurrentImage = (image: IImage) => {
    setCurrentDefaultImage(image);
  };

  return (
    <Modal
      id="edit-slot-modal"
      onCloseModal={onCloseModal}
      modalTile={`Edit Slot`}
      isSaveEnabled={true}
      onClickSave={onClickSave}
      show={isModalOpen}
      variant={'default'}
      sizeType="middle-page"
      fullscreen="md-down"
    >
      <div id="EditSlotModal">
        <div className="slot-modal-body">
          <div className="first_col">
            <Form.Group className="alias-box" controlId="slot-alias-name">
              <Form.Label>Edit slot name:</Form.Label>
              <div className="input-icon-group">
                <Form.Control
                  placeholder="Slot name"
                  onChange={(evt) => {
                    if (evt.target.value.length <= 30) {
                      handleOptionsChange('alias', evt.target.value);
                    }
                  }}
                  type="text"
                  value={formValues.alias}
                />
                <Pencil />
              </div>
            </Form.Group>
            <DefaultText
              slotType={slotType}
              text={slotInternals?.text}
              handleOptionsChange={handleOptionsChange}
              fontFamily={slotInternals?.fontFamily}
            />
            <SelectCollections
              markPendingChanges={(val: boolean) => setHasPendingChanges(val)}
              nativeCollectionsLoaded={nativeCollectionLoaded}
              slotType={slotType}
              onDeleteCollection={onDeleteCurrentCollection}
              currentCollections={currentCollections}
              shopId={shopId!}
              handleSelectedNewCollection={handleSelectedNewCollection}
              defaultImage={defaultImageLoaded}
              templateId={props.templateId}
            />
          </div>
          <div className="second_col">
            <SlotProperties
              slotType={slotType}
              formValues={formValues}
              handleOptionsChange={handleOptionsChange}
            />
            <CustomizableConfigs
              slotType={slotType}
              Customizable={formValues.Customizable!}
              formValues={formValues}
              handleOptionsChange={handleOptionsChange}
              slot={selectedSlot}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditSlotModal;
