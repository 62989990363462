import React, {useState} from 'react';
import {ReactComponent as ColorIcon} from '../../../../assets/icons/color-icon.svg';
import {ReactComponent as CollectionIcon} from '../../../../assets/icons/collection-icon.svg';
import {ReactComponent as ImageIcon} from '../../../../assets/icons/image-icon.svg';
import DropdownButton from '../DropdownButton';
import {IColor, IImage, IExceptionResult} from '@gfxco/contracts';
import {IExceptionNextStep, IList, ITagList} from '../types';
import './FirstStatement.scss';

export interface RequiredProps {
  colors: IColor[];
  tags: ITagList[];
  images: IImage[];
  exception: IExceptionResult;
  onNextStep: (params: IExceptionNextStep) => void;
}
export interface OptionalProps {}

type FirstStatementProps = RequiredProps & OptionalProps;

export const FirstStatement: React.FC<FirstStatementProps> = (props) => {
  const [typeSelected, setTypeSelected] = useState<
    'tag' | 'image' | 'color' | 'fillColor' | null
  >(null);
  const exceptionDraft = props.exception || {};

  const handleSelect = (item: IList) => {
    const {color, tag, image} = item;
    if (!typeSelected) return;

    let exceptions = exceptionDraft.exceptions;
    let newException: IExceptionResult = {type: typeSelected};

    if (
      (typeSelected === 'color' && exceptionDraft.color !== color?.name) ||
      (typeSelected === 'tag' && exceptionDraft.collectionId !== tag?.id) ||
      (typeSelected === 'image' && exceptionDraft.imageId !== image?.id)
    ) {
      exceptions = [];
    }

    if (typeSelected === 'color' && color) newException.color = color.name;
    if (typeSelected === 'tag' && tag) {
      newException.tag = tag.tag;
      newException.collectionId = tag.id;
    }
    if (typeSelected === 'image' && image)
      newException = {...newException, imageId: image.id, image};

    const nextStep = {
      step: 2,
      data: {exception: {...newException, exceptions}},
    };
    props.onNextStep(nextStep);
  };

  const handleOnCloseDropdown = () => {
    setTypeSelected(null);
  };

  const getSelectedItem = (type: string) => {
    const {tag, image, color, collectionId} = exceptionDraft;
    if (type === 'tag' && tag && collectionId) {
      return [{tag: {tag, id: collectionId}}];
    }

    if (type === 'image' && image) return [{image}];

    if (type === 'color' && color) {
      const colorSelected = props.colors.find((c) => c.name === color);
      if (colorSelected) return [{color: colorSelected}];
    }

    return [];
  };

  return (
    <div id="FirstStatement">
      <div className="header">
        <span className="title">Add an Exception</span>
        <div className="divider"></div>
      </div>
      <div className="content-step">
        <div className="content-description">
          <span className="content-title">Select your IF Statement</span>
          <span className="content-subtitle">
            Set up the initial condition; once this requirement is met, the
            actions specified in the following step will be executed in your
            design template.
          </span>
        </div>
        <div className="body">
          <DropdownButton
            type="tag"
            items={props.tags}
            icon={<CollectionIcon />}
            text="Select a collection"
            hoverText="By choosing this option, you will have the ability to establish exceptions exclusively based on other collections and sub-collections"
            onSelect={handleSelect}
            onClose={handleOnCloseDropdown}
            onClick={() => setTypeSelected('tag')}
            selected={typeSelected === 'tag'}
            selectedItems={getSelectedItem('tag')}
          />

          <DropdownButton
            type="image"
            items={props.images}
            icon={<ImageIcon />}
            text="Select an Image"
            hoverText=" By choosing this option, you will have the ability to establish exceptions based on individual images and collections."
            onSelect={handleSelect}
            onClose={handleOnCloseDropdown}
            onClick={() => setTypeSelected('image')}
            selected={typeSelected === 'image'}
            selectedItems={getSelectedItem('image')}
          />

          <DropdownButton
            type="color"
            items={props.colors}
            icon={<ColorIcon />}
            text="Select a Color"
            hoverText="By choosing this option, you will have the ability to establish exceptions based on individual images, collections, and font colors."
            onSelect={handleSelect}
            onClose={handleOnCloseDropdown}
            onClick={() => setTypeSelected('color')}
            selected={typeSelected === 'color'}
            selectedItems={getSelectedItem('color')}
          />
        </div>
      </div>
      <div className="footer">
        <div className="divider"></div>
      </div>
    </div>
  );
};

export default FirstStatement;
