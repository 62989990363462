import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {IInstallationConfigs, ShopConfigsResponse} from '@gfxco/contracts';
import ShopifyPublishModal from '../../../components/ShopifyPublishModal';

import StepItem from '../StepItem';
import StepItemLine from '../StepItemLine';

interface RequiredProps {
  isLoading: boolean;
  isOwner: boolean;
}

interface OptionalProps {
  configs?: IInstallationConfigs;
  shopConfigs?: ShopConfigsResponse;
}

type HomeStepsProps = RequiredProps & OptionalProps;

const HomeSteps: React.FC<HomeStepsProps> = ({
  configs,
  shopConfigs,
  isLoading,
  isOwner,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPublishModal, setShowPublishModal] = React.useState(false);
  const templateStepStatus = configs?.totalTemplates
    ? 'completed'
    : 'not-completed';
  const publishStepStatus = configs?.totalPublished
    ? 'completed'
    : 'not-completed';
  const fulfillmentStepStatus =
    configs?.printerSync || shopConfigs?.artsWebhook
      ? 'completed'
      : 'not-completed';

  return (
    <div id="HomeSteps" className="home-steps">
      <div className="home-line">
        <StepItemLine
          steps={[
            {
              name: '1',
              status: templateStepStatus,
            },
            {
              name: '2',
              status: publishStepStatus,
            },
            {
              name: '3',
              status: fulfillmentStepStatus,
            },
          ]}
        />
      </div>
      <div>
        <StepItem
          title="Create a new template"
          loading={isLoading}
          status={templateStepStatus}
          description="Create a design that aligns with your brand guidelines from the ground up"
          icon="addTemplate"
          onClick={() => navigate(`/designs/create${location.search}`)}
          disabled={!isOwner}
        />
        <StepItem
          title="Publish your first design"
          status={publishStepStatus}
          loading={isLoading}
          description="See how it works! Publish your first design on your store"
          icon="publishDesign"
          disabled={!isOwner}
          onClick={() => setShowPublishModal(true)}
        />
        <StepItem
          title="Set up your fullfilment"
          status={fulfillmentStepStatus}
          description="Set up your webhook or printer configuration"
          loading={isLoading}
          icon="fulfillment"
          disabled={!isOwner}
          onClick={() => navigate('/printers')}
        />
      </div>
      {configs?.template && (
        <ShopifyPublishModal
          show={showPublishModal}
          onCloseModal={() => setShowPublishModal(false)}
          design={configs?.template}
        />
      )}
    </div>
  );
};

export default HomeSteps;
