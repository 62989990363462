import React from 'react';
import {ELoadingStates, IUpdateShopParams, constants} from '@gfxco/contracts';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {selectShop, selectShopConfigs} from '../../features/shops/selectedShop';
import {
  getGeneralShopConfigsAsync,
  selectGeneralConfigsShops,
  selectGeneralConfigsFetchLoading,
} from '../../features/shops/loadInstallationConfigs';
import {selectUserPermissions} from '../../features/user';

import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import {ExclamationTriangle} from 'react-bootstrap-icons';

import HomeSteps from './HomeSteps';
import HelpBoxItem from './HelpBoxItem';
import ConfirmationModal from './ConfirmationModal';
import {GFXEditor} from '../../components/GFXEditor';

import './HomePage.scss';

const {USER_PERMISSIONS} = constants;

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const shopSelected = useAppSelector(selectShop);
  const userPermissions = useAppSelector(selectUserPermissions);
  const isOwner =
    (userPermissions && userPermissions.includes(USER_PERMISSIONS.ALL)) ||
    false;
  const shopConfigs = useAppSelector(selectShopConfigs);
  const shopId = shopSelected?.id;
  const configs = useAppSelector((state) => selectGeneralConfigsShops(state));
  const fetchStatus = useAppSelector((state) =>
    selectGeneralConfigsFetchLoading(state),
  );

  React.useEffect(() => {
    getConfigs();
  }, [shopId]);

  const getConfigs = async () => {
    if (!shopId) return;
    const params: IUpdateShopParams = {
      shopId,
    };

    dispatch(getGeneralShopConfigsAsync(params));
  };

  const showBanner =
    shopConfigs &&
    (['', undefined, null].includes(shopConfigs.stripeSubscriptionToken) ||
      shopConfigs.stripeSubscriptionStatus !== 'active') &&
    (['', undefined, null].includes(shopConfigs.shopifySubscriptionId) ||
      !shopConfigs.shopifySubscriptionStatus);

  return (
    <div id="HomePage">
      {showBanner && (
        <div className="payment-banner">
          <ExclamationTriangle />
          <span className="banner-text">
            Oops! We couldn&apos;t find an active subscription, Check your
          </span>
          <a>billing settings on shopify</a>
          <span className="banner-text"> to update your payment method.</span>
        </div>
      )}

      <div className="home-body">
        <div className="home-body-text">
          <div className="home-header-title">
            <span>Complete your store installation</span>
            <h2 className="home-title">Let’s get started!</h2>
            <span className="home-title-description">
              Launch your store instantly with seamless fulfillment integration
            </span>
          </div>
          <HomeSteps
            configs={configs}
            shopConfigs={shopConfigs}
            isLoading={fetchStatus === ELoadingStates.LOADING}
            isOwner={isOwner}
          />
          {fetchStatus === ELoadingStates.FAILED && isOwner && (
            <Alert variant="danger">
              There was an error loading your permissions
            </Alert>
          )}
        </div>
        {configs && configs.template && configs.template.id && (
          <div className="home-editor">
            <Badge className="badge-editor">Try this on your store!</Badge>
            <GFXEditor
              height={730}
              width={650}
              templateId={`${configs.template.id}`}
            />
          </div>
        )}
      </div>
      <div className="home-footer">
        <HelpBoxItem
          title="Inspire yourself"
          description="Check out our live templates"
        />
        <HelpBoxItem
          title="Become an expert!"
          description="Explore our documentation to discover all our tools and features"
          image="documentation"
        />
        <HelpBoxItem
          title="We’d like to hear from you!"
          description="Let us know how we can assist you"
          image="email"
        />
      </div>
      <ConfirmationModal />
    </div>
  );
};

export default HomePage;
